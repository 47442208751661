import { ReactElement, ReactNode, useEffect, useState, useMemo } from 'react'
import { I18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'

import { useRouteMatch, useHistory } from 'react-router'
import { useCookie } from '../cookies'
import { useLogin, Language as UserLanguage } from '../login'

import { apiToLanguage, languageToApi } from './convert'

import { Language, languages, defaultLanguage, isLanguage, asLanguage } from './language'
import { context } from './context'
import { localiseURL } from './localise-url'

type Props = {
    i18n: I18n
    activate?: (i18n: I18n, language: Language) => Promise<void>
    onUserLanguageChange?: (language: UserLanguage) => Promise<void>
    children: ReactNode | null
}

export function LocaleProvider(props: Props): ReactElement<typeof context.Provider> {
    const { i18n, activate, onUserLanguageChange } = props

    const [fromURL, setURL] = useURLLanguage()
    const [fromCookie, setCookie] = useCookieLanguage()
    const [fromUser, setUser] = useUserPreference(onUserLanguageChange)

    const fromPref = fromUser ?? fromCookie

    const [redirectedFrom, setRedirectedFrom] = useState<Language | null>(null)

    useEffect(
        function (): void {
            async function change(): Promise<void> {
                if (!fromPref || fromURL === fromPref) {
                    return
                }

                setRedirectedFrom(fromURL)

                await activate?.(i18n, fromPref)
                setURL(fromPref)
            }

            void change()
        },
        [fromPref, fromURL],
    )

    const ctx = useMemo(
        function () {
            async function changeLanguage(language: string): Promise<void> {
                if (!isLanguage(language)) {
                    // Avoid changing to a valid language
                    return
                }

                await setUser(language)
                setCookie(language)
                setURL(language)
                setRedirectedFrom(null)

                await activate?.(i18n, language)
            }

            return {
                language: fromURL,
                languages,
                isLanguage,
                localiseURL,
                changeLanguage,
                redirectedFrom,
            }
        },
        [fromURL, languages, isLanguage, localiseURL, redirectedFrom],
    )

    return (
        <context.Provider value={ctx}>
            <I18nProvider i18n={i18n}>{props.children}</I18nProvider>
        </context.Provider>
    )
}

const pattern = `/:locale(${languages.join('|').replace(/-/g, '_')})`

// Return the language that is specified by the current url
function useURLLanguage(): [Language, (language: Language) => void] {
    const match = useRouteMatch<{ locale: string }>(pattern)
    const history = useHistory()

    function setLanguage(language: Language): void {
        history.push({
            ...history.location,
            pathname: localiseURL(history.location.pathname, language),
        })
    }

    if (!match) {
        return [defaultLanguage, setLanguage]
    }

    const { locale } = match.params
    const language = asLanguage(locale.replace(/_/g, '-'))

    return [language, setLanguage]
}

function useCookieLanguage(): [Language | undefined, (language: Language) => void] {
    const [cookie, setCookie] = useCookie('language2')
    return [cookie as Language | undefined, setCookie as (language: Language) => void]
}

async function noop(): Promise<void> {
    // noop
}

function useUserPreference(
    onUserLanguageChange?: (lang: UserLanguage) => Promise<void>,
): [Language | undefined, (language: Language) => Promise<void>] {
    const { user } = useLogin()
    if (!user) {
        return [undefined, noop]
    }

    async function set(language: Language): Promise<void> {
        const lang = languageToApi[language]
        await onUserLanguageChange?.(lang)
    }

    const language = apiToLanguage[user.language]
    return [language, set]
}
