import { ReactElement } from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'

type StatusProps = {
    code: number
}

/**
 * Status renders nothing but sets the `status` field of the
 * react router static context to the value of the `code` prop.
 *
 * That value can be used during SSR to determine the correct HTTP
 * status code. See <https://reactrouter.com/web/api/StaticRouter/context-object>.
 */
export function Status(props: StatusProps): ReactElement {
    const { code } = props

    function render(ctx: RouteComponentProps<{}>): null {
        if (ctx.staticContext) {
            // @ts-expect-error: We're setting an unknown property
            ctx.staticContext.status = code
        }
        return null
    }

    return <Route render={render} />
}
