import { ReactElement, ReactNode, ComponentType, ErrorInfo, PureComponent } from 'react'
import { DiscogsError } from '../error'

export type Props = {
    fallback: ComponentType<DiscogsError>
    onError?: (error: Error, info?: ErrorInfo) => void
    children: ReactNode
}

type State = {
    error: DiscogsError | null
}

export class ErrorBoundary extends PureComponent<Props, State> {
    state: State = {
        error: null,
    }

    static getDerivedStateFromError(error: Error): Partial<State> {
        return { error }
    }

    componentDidCatch(error: Error, info?: ErrorInfo): void {
        this.props.onError?.(error, info)
    }

    render(): ReactElement {
        if (this.state.error) {
            const { fallback: Fallback } = this.props

            return <Fallback {...this.state.error} />
        }

        return <>{this.props.children}</>
    }
}
