import { ReactNode, useRef, useEffect } from 'react'
import { useDebounce } from 'use-debounce'

import { autocomplete, AutocompleteResult, Category } from '../../../services/autocomplete'
import { addState, Dispatcher } from '../../add-state'
import { track } from '../../data-layer'
import { trackSearch } from '../../interactions'

import { SearchUI } from './ui'

export type Props = {
    base?: string
    navigate?(location: string): void
    className?: string
    initialQuery?: string
    initialCategory?: Category
    children?: ReactNode
}

export type State = {
    value: string
    focus: boolean
    category: Category
    working: boolean
    results: null | AutocompleteResult[]
    index: number | 'advanced'
    location?: string
}

export type Action =
    | { type: 'focus' }
    | { type: 'blur' }
    | { type: 'edit'; value: string }
    | { type: 'category'; category: Category }
    | { type: 'autocomplete'; results: AutocompleteResult[] }
    | { type: 'work' }
    | { type: 'error'; error: Error }
    | { type: 'abort' }
    | { type: 'previous' }
    | { type: 'next' }
    | { type: 'select'; index: number }
    | { type: 'navigate' }

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'focus':
            return { ...state, focus: true, index: -1 }
        case 'blur':
            return { ...state, focus: false, index: -1 }
        case 'edit':
            return { ...state, value: action.value }
        case 'category':
            return { ...state, category: action.category }
        case 'work':
            return { ...state, working: true }
        case 'autocomplete':
            trackSearch({
                term: state.value,
                type: state.category,
                results: action.results.length,
            })
            return { ...state, working: false, results: action.results }
        case 'error':
            return { ...state, working: false, results: null }
        case 'abort':
            return { ...state, working: false }
        case 'previous': {
            const len = state.results?.length ?? 0
            const idx = state.index

            if (len === 0 || idx === 0) {
                return { ...state, index: -1 }
            }
            if (idx === -1) {
                return { ...state, index: 'advanced' }
            }
            if (idx === 'advanced') {
                return { ...state, index: len - 1 }
            }

            return { ...state, index: (len + idx - 1) % len }
        }
        case 'next': {
            const len = state.results?.length ?? 0
            const idx = state.index
            if (len === 0 || idx === 'advanced') {
                return { ...state, index: -1 }
            }
            if (idx === len - 1) {
                return { ...state, index: 'advanced' }
            }

            return { ...state, index: (len + idx + 1) % len }
        }
        case 'select':
            return { ...state, index: action.index }
        case 'navigate': {
            const { index, results } = state
            if (index === 'advanced') {
                return { ...state, location: '/search/advanced' }
            }

            if (!index || !results || index > results.length - 1) {
                return state
            }

            track({ event: 'resultSelected', category: 'search', result: results[index] })

            if (index === -1) {
                const location = `/search?q=${state.value.replace(' ', '+')}&type=${state.category}`
                return { ...state, location }
            }

            const location = results[index].uri
            return { ...state, location }
        }
        default:
            return state
    }
}

function setLocation(url: string): void {
    window.location.href = url
}

export function useAutocomplete(props: Props, state: State, dispatch: Dispatcher<Action>): void {
    const { value, category, focus } = state
    const ref = useRef<AbortController | null>(null)
    const [debounced] = useDebounce(value, 400)

    function abort(): void {
        if (ref.current) {
            ref.current.abort()
            dispatch({ type: 'abort' })
        }
    }

    useEffect(
        function (): () => void {
            if (!focus || value.length <= 2) {
                return abort
            }

            // AbortController might not exist
            /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
            if (window.AbortController) {
                ref.current = new AbortController()
            }

            dispatch({ type: 'work' })
            autocomplete(value, category, {
                signal: ref.current?.signal,
                base: props.base,
            })
                .then((results: AutocompleteResult[]): void => dispatch({ type: 'autocomplete', results }))
                .catch((error: Error): void => dispatch({ type: 'error', error }))

            return abort
        },
        [debounced, category, focus],
    )

    // If the location changes, navigate to it
    useEffect(
        function () {
            if (state.location) {
                const { navigate = setLocation } = props
                navigate(state.location)
            }
        },
        [state.location],
    )
}

export const initial = (props: Props): State => ({
    value: props.initialQuery ?? '',
    category: props.initialCategory ?? Category.all,
    focus: false,
    working: false,
    results: null,
    index: -1,
})

export default addState(SearchUI, reducer, initial, useAutocomplete)
