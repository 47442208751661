import { ReactElement } from 'react'
import { Redirect, RedirectProps, RouteComponentProps, Route } from 'react-router'

export function SoftRedirect(props: RedirectProps): ReactElement {
    return (
        <Route
            render={function (ctx: RouteComponentProps<{}>): ReactElement {
                if (ctx.staticContext) {
                    // @ts-expect-error
                    ctx.staticContext.status = 302
                }
                return <Redirect {...props} />
            }}
        />
    )
}
