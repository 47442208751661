import { ReactElement, createContext, useContext } from 'react'

export type Flags = {
    [name: string]: boolean | number
}

export const context = createContext<Flags>({})

export function useFlag(name: string): boolean {
    const flags = useContext(context)
    return Boolean(flags[name])
}

type Props = {
    flags: Flags
    children: ReactElement
}

export function FlagProvider(props: Props): ReactElement {
    const { flags, children } = props
    return <context.Provider value={flags}>{children}</context.Provider>
}
