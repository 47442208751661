import { ReactElement, isValidElement } from 'react'
import { Trans } from '@lingui/macro'
import { I18n, MessageDescriptor } from '@lingui/core'

import { useLocalisation } from '../../i18n'
import { useErrors, Err, Message } from '../errors'
import { Banner } from '../banner'

export function ErrorBanners(): ReactElement {
    const { errors, dismiss } = useErrors()
    const { i18n } = useLocalisation()

    const banners = errors.map(function (error: Err): ReactElement {
        const fixed = fix(i18n, error.message)
        return (
            <Banner key={error.id} error onDismiss={(): void => dismiss(error.id)}>
                <b>
                    <Trans>Something went wrong</Trans>
                </b>
                <br />
                {error.context && render(i18n, error.context)}
                {error.context && fixed && ': '}
                {fixed}
            </Banner>
        )
    })

    return <>{banners}</>
}

function fix(i18n: I18n, message: Message): string | ReactElement | null {
    if (message === "(0, '')") {
        return null
    }

    if (message === 'Subgraph errors redacted') {
        return null
    }

    return render(i18n, message)
}

function render(i18n: I18n, message: Message): string | ReactElement {
    if (typeof message === 'string') {
        return message
    }

    if (isValidElement(message)) {
        return message
    }
    return i18n._(message as MessageDescriptor)
}
