import { ReactElement, ReactNode, ComponentType } from 'react'
import { Location } from 'history'
import { match, useLocation } from 'react-router'
import { Trans } from '@lingui/macro'

import { User, LoginProvider as Provider, loginURL, useLogin } from '../../lib/login'
import { SoftRedirect } from '../../lib/components/soft-redirect'
import { useFlag } from '../../lib/flags'
import { useUserDataQuery } from '../../api/types'

type LoginStatus = {
    user: User | null
    cartCount: number | null
    messageCount: number | null
    loading: boolean
}

export function useLoginStatus(): LoginStatus {
    const ssrLogin = useFlag('ssr_login')

    const { data, loading } = useUserDataQuery({
        ssr: ssrLogin,
    })

    return {
        loading,
        user: (data?.viewer ?? null) as User | null,
        cartCount: data?.cartCount.totalCount ?? null,
        messageCount: data?.unreadMessagesCount.totalCount ?? null,
    }
}

type Props = {
    isImpersonated?: boolean
    children: ReactElement
}

export function LoginProvider(props: Props): ReactElement {
    return <Provider {...props} {...useLoginStatus()} />
}

type RequireLoginProps = {
    fallback?: ComponentType<{}>
    children: ReactNode | ReactNode[]
}

function url(location: Location): string {
    return `${location.pathname}${location.search}${location.hash}`
}

export function RequireLogin(props: RequireLoginProps): ReactElement {
    const { fallback: Fallback = DefaultFallback, children } = props
    const { loggedIn, loading } = useLogin()
    const location = useLocation()

    if (!loggedIn) {
        return (
            <>
                {!loading && <SoftRedirect to={loginURL(url(location))} />}
                <Fallback />
            </>
        )
    }

    return <>{children}</>
}

function DefaultFallback(): ReactElement {
    return <Trans>You need to be logged in to see this page.</Trans>
}

export function requireLogin<T extends { match: match<{ discogsId: string }> }>(
    Component: ComponentType<T>,
    fallback: ComponentType<{}> = DefaultFallback,
): ComponentType<T> {
    return function WithRequire(props: T): ReactElement {
        return (
            <RequireLogin fallback={fallback}>
                <Component {...props} />
            </RequireLogin>
        )
    }
}
