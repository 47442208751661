import { useMemo } from 'react'
import { useCookie } from './cookies'

const cookieName = 'dsjspersist2'

export function usePersist<T>(name: string): [T | undefined, (value: T) => void] {
    const [cookie, setCookie] = useCookie(cookieName)

    const data = parse(cookie)
    const value = data[name] as T | undefined

    return useMemo(
        function () {
            function set(value: T): void {
                const newData = { ...data, [name]: value }
                const str = JSON.stringify(newData)
                setCookie(str)
            }
            return [value, set]
        },
        [name, JSON.stringify(value), data],
    )
}

function parse(data: string | undefined): Record<string, unknown> {
    if (!data) {
        return {}
    }

    try {
        return JSON.parse(data) ?? null
    } catch (err) {
        return {}
    }
}
