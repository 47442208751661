import { ReactElement, createContext, useMemo, useContext } from 'react'
import { Language, Currency } from '../api/types'

export { Language }

export function loginURL(returnTo: string): string {
    const return_to = encodeURIComponent(returnTo)
    return `https://www.discogs.com/login?return_to=${return_to}`
}

export function login(returnTo: string = window.location.pathname): void {
    // @ts-expect-error: Typescript does not get the assignment here
    window.location = loginURL(returnTo)
}

export type User = {
    discogsId: number
    username: string
    avatarUrl?: string
    isAdmin?: boolean
    language: Language
    currency?: Currency
}

export type LoginContext = {
    loading: boolean
    user: User | null
    cartCount: number | null
    messageCount: number | null
    loggedIn: boolean
    isImpersonated: boolean
    redirect: () => boolean
}

const context = createContext<LoginContext>({
    loading: true,
    user: null,
    cartCount: null,
    messageCount: null,
    loggedIn: false,
    isImpersonated: false,
    redirect: () => true,
})

export function useLogin(): LoginContext {
    return useContext(context)
}

type ProviderProps = {
    loading: boolean
    user: User | null
    cartCount: number | null
    messageCount: number | null
    isImpersonated?: boolean
    children: ReactElement
}

export function LoginProvider(props: ProviderProps): ReactElement {
    const { loading, user, messageCount, cartCount, isImpersonated } = props

    const value = useMemo(
        function () {
            function redirect(): boolean {
                if (user === null) {
                    login()
                    return true
                }
                return false
            }
            return {
                loading,
                user,
                login,
                loggedIn: user !== null,
                messageCount,
                cartCount,
                redirect,
                isImpersonated: Boolean(isImpersonated),
            }
        },
        [loading, user, messageCount, cartCount, isImpersonated],
    )

    return <context.Provider value={value} {...props} />
}
