import { ButtonHTMLAttributes, ReactElement } from 'react'
import css from './style.css'
import classnames from 'classnames'

import { Link, LinkProps } from '../link'

const colors = {
    green: css.green,
    blue: css.blue,
    grey: css.grey,
    link: css.link,
    red: css.red,
    nav: css.nav,
    greyLink: `${css.link} ${css.grey}`,
    black: css.black,
}

type ButtonColor = keyof typeof colors

type ButtonConfig = {
    color?: ButtonColor
    className?: string
    small?: boolean
    disabled?: boolean
}

function className(cfg: ButtonConfig): string {
    const { className: cname, color = 'grey', small = false, disabled } = cfg
    return classnames(css.button, colors[color], small && css.small, disabled && css.disabled, cname)
}

type ButtonProps = ButtonConfig & ButtonHTMLAttributes<HTMLButtonElement>

export function Button(props: ButtonProps): ReactElement {
    const { children, small, color, ...rest } = props
    return (
        <button type='button' {...rest} className={className(props)}>
            {children}
        </button>
    )
}

type AnchorButtonProps = ButtonConfig & LinkProps

export function AnchorButton(props: AnchorButtonProps): ReactElement {
    const { children, small, color, disabled, ...rest } = props
    return (
        <Link {...rest} className={className(props)}>
            {children}
        </Link>
    )
}
