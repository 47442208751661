import { createContext, useContext } from 'react'
import { Language } from './language'
import { localiseURL } from './localise-url'

export type Context = {
    language: Language
    languages: readonly Language[]
    changeLanguage(language: Language): void
    localiseURL: typeof localiseURL
    redirectedFrom: Language | null
}

export const context = createContext<Context | null>(null)

export function useLocalisation(): Context {
    const ctx = useContext(context)
    if (ctx) {
        return ctx
    }

    throw new Error('LocaleProvider not found')
}
