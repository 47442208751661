import { useEffect, useRef } from 'react'

export function usePrevious<T>(value: T): T {
    const ref = useRef(value)

    useEffect(
        function () {
            ref.current = value
        },
        [value],
    )

    return ref.current
}
