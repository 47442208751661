import { ReactElement } from 'react'
import { t } from '@lingui/macro'

import { Category } from '../../../services/autocomplete'
import { useLocalisation } from '../../i18n'
import { useFlag } from '../../flags'

import { Content } from '../content'
import Logo from '../logo'

import Search from '../search'
import { HeaderDropdown } from '../header-dropdown'
import { User } from '../user'
import { User as Profile } from '../../login'
import { DashboardLink } from '../dashboard'
import { Cart } from '../cart'
import { Inbox } from '../inbox'
import { Link } from '../link'

import css from './styles.css'
import { categories } from './dropdowns'

type Props = {
    search: {
        initialQuery?: string
        initialCategory?: Category
        base?: string
        navigate?(location: string): void
    }
    cart: {
        count: number
    }
    inbox: {
        count: number
    }
    user?: Profile
    loading?: boolean
}

export function HeaderNav(props: Props): ReactElement | null {
    const { search, cart, user, inbox, loading } = props

    const { i18n } = useLocalisation()
    const esi = useFlag('esi_header')

    if (esi) {
        return null
    }

    return (
        <>
            <header className={css.header}>
                <Content>
                    <div className={css.wrapper}>
                        <Link
                            href='/'
                            className={css.logo}
                            title={t(i18n)`Go to Discogs.com homepage`}
                            id='discogs-logo'
                        >
                            <Logo />
                        </Link>
                        <Search {...search} className={css.search}>
                            <HeaderDropdown categories={categories} />
                        </Search>
                        <nav className={css.profile}>
                            <ul className={css.icons}>
                                {!loading && user && (
                                    <li>
                                        <DashboardLink id='dashboard' />
                                    </li>
                                )}
                                {!loading && user && (
                                    <li>
                                        <Inbox {...inbox} id='inbox' />
                                    </li>
                                )}
                                {!loading && (
                                    <li>
                                        <Cart {...cart} id='cart' />
                                    </li>
                                )}
                                <li>
                                    <User profile={user} loading={loading} />
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Content>
            </header>
            <div className={css.placeholder} />
        </>
    )
}

HeaderNav.defaultProps = {
    cart: {
        count: 0,
    },
    inbox: {
        count: 0,
    },
    search: {},
}
