import { ReactElement } from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'

type Props = {
    locale: string
}

export function RemoveLocale(props: Props): ReactElement | null {
    const { locale } = props

    const home = useRouteMatch({
        path: `/${locale}`,
        exact: true,
    })
    const match = useRouteMatch<{ '0': string }>(`/${locale}/*`)
    if (home) {
        return <Redirect to='/' />
    }

    if (match) {
        const clean = `/${match.params[0]}`
        return <Redirect to={clean} />
    }

    return null
}
