import { ComponentType, ReactNode, ReactElement, createContext, useState, useMemo } from 'react'

import { HeadProvider } from 'react-head'
import { ApolloProvider, ApolloClient } from '@apollo/client'
import { AmpedProvider } from '@discogs/amped/provider'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { Currency } from './api/types'

import { FlagProvider, Flags } from './lib/flags'
import { CookieProvider } from './lib/cookies'
import { CurrencyProvider } from './lib/currency'

import { ErrorsProvider } from './lib/components/errors'
import { ShortcutProvider } from './lib/components/shortcuts'
import { DiscogsError } from './lib/components/error'
import { DataLayerProvider, DataLayer } from './lib/data-layer'

import { LoginProvider } from './components/login'

import { ErrorPage } from './pages/error'
import { ThemeProvider, createTheme, PaletteMode } from '@mui/material'
import { getDesignTokens } from './discogsTheme'

type Props = {
    flags: Flags
    cookie: string
    client: ApolloClient<unknown>
    currency: Currency
    error?: DiscogsError
    isImpersonated?: boolean
    router: ComponentType
    localeProvider: ComponentType
    children: ReactNode

    // For SSR only
    headTags?: ReactElement[]
    dataLayer?: DataLayer
}
// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
export const ColorModeContext = createContext({ toggleColorMode: () => {} })

export function Provider(props: Props): ReactElement {
    const [mode, setMode] = useState<PaletteMode>('light')

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'))
            },
        }),
        [],
    )

    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])

    const {
        flags,
        cookie,
        client,
        currency,
        error,
        router: Router,
        localeProvider: LocaleProvider,
        dataLayer,
        children,
        headTags,
        isImpersonated,
    } = props

    LicenseInfo.setLicenseKey(
        'd286a7d2cfe76b2427d2dfe3bf17d262Tz04Njk2OSxFPTE3NDI2ODExOTEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
    )

    const render = error ? <ErrorPage message={error.message} status={error.status} /> : children

    const ldConfig = {
        clientSideID: '6512027f6f9bd01256bd7ef0',
        user: {
            key: String(flags.ld_flag_int),
            kind: 'user',
        },
    }

    return (
        <ErrorsProvider>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <LDProvider {...ldConfig}>
                        <AmpedProvider>
                            <FlagProvider flags={flags}>
                                <HeadProvider headTags={headTags}>
                                    <CookieProvider cookie={cookie}>
                                        <DataLayerProvider dataLayer={dataLayer ?? []}>
                                            <ShortcutProvider>
                                                <ApolloProvider client={client}>
                                                    <Router>
                                                        <LoginProvider isImpersonated={isImpersonated}>
                                                            <LocaleProvider>
                                                                <CurrencyProvider currency={currency}>
                                                                    {render}
                                                                </CurrencyProvider>
                                                            </LocaleProvider>
                                                        </LoginProvider>
                                                    </Router>
                                                </ApolloProvider>
                                            </ShortcutProvider>
                                        </DataLayerProvider>
                                    </CookieProvider>
                                </HeadProvider>
                            </FlagProvider>
                        </AmpedProvider>
                    </LDProvider>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </ErrorsProvider>
    )
}
