import { ReactElement } from 'react'

export type Props = JSX.IntrinsicAttributes & {
    className?: string
    alt?: string
    style?: {
        [key: string]: string | number
    }
}

export default function Logo(props: Props): ReactElement {
    const { className, ...rest } = props
    return (
        <svg
            viewBox='0 0 456.7 165.6'
            xmlns='http://www.w3.org/2000/svg'
            className={className}
            role='img'
            {...rest}
            aria-label='Discogs'
        >
            <g fill='currentColor'>
                <path d='M277.3 32.3c-.2 0-.3 0-.5-.1.2.1.4.1.5.1zM27.3 0H0v136.7h32.1c16.8 0 28.5-5 35.2-14.9 6.7-9.9 10-28 10-54.1 0-25.7-3.3-43.4-10-53.1C60.6 4.9 47.3 0 27.3 0zm18 115c-2.4 4.6-6.7 6.8-12.9 6.8h-4.9V15h4.9c6.3 0 10.6 3.9 13 11.6 2.4 7.7 3.6 21.9 3.6 42.5-.1 26-1.3 41.3-3.7 45.9zM82.6.1h25.8V24H82.6zM82.6 32h25.8v104.7H82.6zM151.7 77.6c-8.2-7.2-13.1-12.1-14.7-14.8-1.6-2.7-2.4-5.4-2.4-8.3 0-2.4.7-4.2 2.1-5.5 1.4-1.3 3.1-1.9 5.2-1.9 2.5 0 4.4 1 5.8 2.9 1.4 1.9 2.1 4.5 2.1 7.6h20.6c0-8.6-2.5-15.3-7.4-20-4.9-4.7-12-7-21.3-7-8.7 0-15.7 2.3-21 6.8-5.3 4.6-8 10.7-8 18.4 0 7.4 1.1 13.6 3.2 18.6 2.1 5 7.1 10.9 15 17.8 6.2 5.3 10.7 9.5 13.4 12.4 2.7 2.9 4.1 5.6 4.1 8.1 0 2.7-.6 4.7-1.9 6.1-1.3 1.4-3.2 2-5.8 2-2.7 0-4.7-.9-6.1-2.6-1.4-1.7-2.1-4.5-2.1-8.3h-20.9c0 9.2 2.5 16.2 7.5 20.8 5 4.6 12.4 6.9 22.3 6.9 9.2 0 16.4-2.5 21.7-7.4 5.3-4.9 7.9-11.8 7.9-20.7 0-5.7-1.4-10.9-4.2-15.5-2.8-4.6-7.8-10-15.1-16.4zM452.6 94.1c-2.8-4.6-7.8-10.1-15-16.4-8.2-7.2-13.1-12.1-14.7-14.8-1.6-2.7-2.4-5.4-2.4-8.3 0-2.4.7-4.2 2.1-5.5 1.4-1.3 3.1-1.9 5.2-1.9 2.5 0 4.4 1 5.8 2.9 1.4 1.9 2.1 4.5 2.1 7.6h20.6c0-8.6-2.5-15.3-7.4-20-4.9-4.7-12-7-21.3-7-8.7 0-15.7 2.3-21 6.8-5.3 4.6-8 10.7-8 18.4 0 7.4 1.1 13.6 3.2 18.6 2.1 5 7.1 10.9 15 17.8 6.2 5.3 10.7 9.5 13.4 12.4 2.7 2.9 4.1 5.6 4.1 8.1 0 2.7-.6 4.7-2 6.1-1.3 1.4-3.2 2-5.8 2-2.7 0-4.7-.9-6.1-2.6-1.4-1.7-2.1-4.5-2.1-8.3h-20.9c0 9.2 2.5 16.2 7.5 20.8 5 4.6 12.4 6.9 22.3 6.9 9.2 0 16.4-2.5 21.7-7.4 5.3-4.9 7.9-11.8 7.9-20.7-.1-5.8-1.5-10.9-4.2-15.5zM262 86.4a15.4 15.4 0 0 1 30.8 0 15.4 15.4 0 0 1-30.8 0zm.7 0c0 8.1 6.6 14.7 14.7 14.7s14.7-6.6 14.7-14.7-6.6-14.7-14.7-14.7c-8.2 0-14.7 6.6-14.7 14.7z' />
                <path d='M279.8 86.4c0 1.3-1.1 2.4-2.4 2.4-1.3 0-2.4-1.1-2.4-2.4 0-1.3 1.1-2.4 2.4-2.4 1.3 0 2.4 1.1 2.4 2.4zM225 110.7h-9.7c-.4 4.5-1.1 7.4-2.2 8.8-1.1 1.4-2.8 2-5.1 2-3.7 0-6.1-2.2-7.1-6.5-1.1-4.3-1.6-14.6-1.6-30.9 0-14.1.5-23.8 1.4-29.2.9-5.4 3.3-8.1 7-8.1 2.2 0 3.8.9 4.7 2.8.9 1.9 1.4 6 1.4 12.4h11.3c2.5-5.2 5.6-10 9.5-14.2-1.2-3.7-3.1-6.9-5.5-9.4-5-5.2-12.2-7.8-21.5-7.8-11.7 0-20.5 4.5-26.3 13.5-5.8 9-8.7 22.3-8.7 40 0 17.8 2.8 31.2 8.4 40.1 5.6 9 13.8 13.5 24.7 13.5 9.6 0 16.8-2.2 21.6-6.6 2.4-2.2 4.1-4.9 5.3-8.3-3-3.7-5.5-7.8-7.6-12.1zM334.9 86.5c0 10.2-2.6 19.7-7.3 28 1.6 5.9 3.9 10.4 6.9 13.6 4.1 4.2 9.8 6.3 17.1 6.3 4.3 0 8.1-.6 11.3-1.7 3.2-1.1 5.4-2.6 6.5-4.4.1 1 .2 2 .2 2.8.1.8.1 1.6.1 2.3 0 5.5-1.2 9.6-3.7 12.4-2.5 2.8-6.3 4.3-11.5 4.3-3.4 0-7.2-.4-11.3-1.2-4.1-.8-7.5-1.7-10.1-2.9l-3.2 14.3c3.9 1.8 8.2 3.1 13 4 4.8.9 9.5 1.3 14.1 1.3 11.2 0 20-2.8 26.6-8.4 6.5-5.6 9.8-13.5 9.8-23.7V32h-25.7v2.5c-2.3-1.4-4.6-2.4-6.9-3-2.3-.6-5-.9-8.1-.9-6.3 0-11.6 1.9-15.9 5.8-4.4 3.9-7.5 9.3-9.4 16.1-.3 1.2-.7 2.6-1 4.3 5.4 8.7 8.4 18.8 8.5 29.7m33 30.8c-1.1.9-2.2 1.5-3.3 1.9-1.1.4-2.6.6-4.4.6-2.4 0-4.2-.7-5.5-2.1-1.3-1.4-2.1-3.7-2.4-6.9-.3-3.3-.6-7.5-.8-12.5-.2-5-.3-10.3-.3-15.9a290.19 290.19 0 0 1 1.2-25.2c.4-3.9 1.2-6.5 2.6-7.9 1.4-1.4 3.5-2.1 6.3-2.1 1 0 2.2.2 3.5.4 1.3.3 2.4.7 3.1 1.1v68.6z' />
                <path d='M249.6 86.4c0 7.9 3.3 14.9 8.5 20l5.2-5.7.1.1c-3.8-3.6-6.1-8.7-6.1-14.4 0-11.1 9-20.1 20.1-20.1 3.7 0 7.1 1 10 2.7l3.7-6.8c-4.1-2.3-8.7-3.6-13.7-3.6-15.3 0-27.8 12.5-27.8 27.8zM297.4 86.4c0 11.1-9 20.1-20 20.1-3.4 0-6.5-.8-9.3-2.3l-3.8 6.7c3.9 2.1 8.4 3.3 13.1 3.3 15.3 0 27.8-12.5 27.8-27.8 0-7.6-3.1-14.5-8-19.5l-5.3 5.7c3.4 3.6 5.5 8.4 5.5 13.8z' />
                <path d='M240.8 86.4c0 10.4 4.4 19.8 11.4 26.5l5.3-5.7c-5.5-5.2-8.9-12.6-8.9-20.7a28.8 28.8 0 0 1 28.8-28.8c5.2 0 10 1.4 14.2 3.8l3.8-6.8c-5.3-3-11.4-4.7-18-4.7a36.32 36.32 0 0 0-36.6 36.4zM306.1 86.4a28.8 28.8 0 0 1-42.4 25.4l-3.8 6.8a36.62 36.62 0 0 0 54-32.2c0-10.1-4.1-19.3-10.8-25.9l-5.3 5.7c5.2 5.2 8.3 12.3 8.3 20.2z' />
                <path d='M314.9 86.4a37.68 37.68 0 0 1-55.5 33.1l-3.9 6.9c6.5 3.5 13.9 5.6 21.8 5.6 25.1 0 45.4-20.4 45.4-45.4 0-12.7-5.2-24.2-13.6-32.4l-5.3 5.8c6.9 6.5 11.1 16 11.1 26.4zM231.9 86.4c0 13 5.5 24.7 14.2 33l5.3-5.8a37.35 37.35 0 0 1-11.7-27.2c0-20.7 16.9-37.6 37.6-37.6 6.7 0 13 1.8 18.4 4.9l3.8-6.9c-6.6-3.7-14.2-5.8-22.2-5.8-25-.1-45.4 20.3-45.4 45.4z' />
                <path d='M315.1 47.6l-5.2 5.6c8.6 8.4 14 20.2 14 33.1 0 25.6-20.8 46.4-46.4 46.4-8.1 0-15.6-2.1-22.2-5.7l-3.9 6.9c7.7 4.2 16.6 6.7 26 6.7 29.9 0 54.2-24.3 54.2-54.2-.1-15.2-6.4-28.9-16.5-38.8zM230.9 86.4c0-25.6 20.8-46.4 46.4-46.4 8.2 0 16 2.2 22.7 6l3.7-6.7c-7.6-4.3-16.3-6.8-25.6-6.9h-.9c-29.8 0-53.9 24-54.2 53.7v.5c0 15.5 6.6 29.5 17.1 39.4l5.4-5.8c-9-8.6-14.6-20.5-14.6-33.8z' />
            </g>
        </svg>
    )
}
