import { ReactElement } from 'react'
import { useLocalisation } from '../../i18n'
import { useShortcut, Category } from './use-shortcut'
import { useLogin } from '../../login'
import { useCookie } from '../../cookies'

export function useNavShortcut(keys: string[], title: ReactElement, to: string, category?: Category): void {
    const { language, localiseURL } = useLocalisation()
    const { user } = useLogin()
    const username = user?.username ?? null
    const [keyboard_shortcuts = 'false'] = useCookie('keyboard_shortcuts')

    useShortcut(keys, category ?? 'nav', title, function () {
        if (to.includes('{username}') && !username) {
            return
        }

        if (!JSON.parse(keyboard_shortcuts)) {
            return
        }

        const dest = localiseURL(to.replace('{username}', username ?? ''), language)

        // @ts-expect-error
        window.location = dest
    })
}
