import { ReactElement } from 'react'
import { t } from '@lingui/macro'
import { useLocalisation } from '../../i18n'
import { HeaderIcon } from '../header-icon'
import { Dashboard } from '../icon'

type Props = {
    id: string
}

export function DashboardLink(props: Props): ReactElement {
    const { i18n } = useLocalisation()
    const { id } = props
    return <HeaderIcon label={t(i18n)`Dashboard`} icon={Dashboard} href='/my' id={id} />
}
