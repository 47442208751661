import { ReactElement } from 'react'
import { t } from '@lingui/macro'
import { useLocalisation } from '../../i18n'
import { HeaderIcon } from '../header-icon'
import { ShoppingCart } from '../icon'

type Props = {
    count: number
    id: string
}

export function Cart(props: Props): ReactElement {
    const { count, id } = props
    const { i18n } = useLocalisation()

    return <HeaderIcon count={count} label={t(i18n)`${count} in cart`} icon={ShoppingCart} href='/sell/cart' id={id} />
}
