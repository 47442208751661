import { ReactElement, ReactNode } from 'react'
import { SectionUI } from './ui'

// Eslint and Prettier seem to disagree about spacing here.

type TitleOrHeader =
    | {
          title: ReactElement | string
          altAction?: ReactNode
      }
    | {
          header: ReactElement
      }

export type Props = {
    id: string
    className?: string
    children?: ReactNode
    collapsible?: boolean
    isOpenByDefault?: boolean
} & TitleOrHeader

export const Section = SectionUI
