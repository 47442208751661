import { useEffect } from 'react'

type Defn = {
    [keycode: string]: (evt: KeyboardEvent) => void
}

const activeElements = ['INPUT', 'TEXTAREA', 'SELECT', 'OPTION']

export function useKeyPress(defn: Defn, disable: boolean = false): void {
    useEffect(
        function (): () => void {
            function handler(evt: KeyboardEvent): void {
                if (activeElements.includes(document.activeElement?.tagName ?? 'INACTIVE')) {
                    return
                }

                if (evt.key in defn && !disable) {
                    defn[evt.key](evt)
                    evt.preventDefault()
                }
            }

            document.addEventListener('keydown', handler)
            return (): void => document.removeEventListener('keydown', handler)
        },
        [disable, defn],
    )
}
