import { AnchorHTMLAttributes, ReactElement } from 'react'
import classnames from 'classnames'
import { Link as RLink } from 'react-router-dom'
import { Location } from 'history'

import { useLocalisation } from '../../i18n'

import css from './styles.css'

type AnchorProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>

export type LinkProps = AnchorProps & {
    href: string | Location | undefined
    className?: string
    internal?: boolean
    replace?: boolean
    disabled?: boolean
}

export function Link(props: LinkProps): ReactElement {
    const { language, localiseURL } = useLocalisation()

    const { href, children, internal = false, disabled = false, replace, className, ...rest } = props

    const classname = classnames(css.link, disabled && css.disabled, className)

    if (!href || disabled) {
        return (
            <a className={classname} {...rest}>
                {children}
            </a>
        )
    }

    // @ts-expect-error: TypeScript can't seem to use the right overload here
    const localised = localiseURL(href, language)

    if (internal) {
        return (
            <RLink to={localised} hrefLang={language} className={classname} replace={replace} {...rest}>
                {children}
            </RLink>
        )
    }

    return (
        <a href={stringify(href)} hrefLang={language} className={classname} {...rest}>
            {children}
        </a>
    )
}

function stringify(location: string | Location): string {
    if (typeof location === 'string') {
        return location
    }

    return location.pathname.concat(location.search).concat(location.hash)
}
