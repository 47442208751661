import { ReactElement, FC, useReducer } from 'react'
import { DocumentNode } from 'graphql'

export type Dispatcher<A> = (action: A) => void
export type SideEffects<P, S, A> = (props: P, state: S, dispatch: Dispatcher<A>) => void
export type Reducer<P, S, A> = (props: P, state: S, action: A) => S
export type WithDispatcher<P, A> = P & { dispatch: Dispatcher<A> }
export type WithProps<P, S, A> = WithDispatcher<P & S, A>
export type InitialFromProps<P, S> = (props: P) => S
export type Initial<P, S> = S | InitialFromProps<P, S>

/**
 * Add state to a stateless component.
 *
 * The state is managed by the reducer function, which has a similar signature to useReducer,
 * but an added argument that holds the current props of the component:
 *
 *     reducer(props : Props, state : State, action : Action) : State
 *
 * Side effects can be added by setting the sideEffect argument, which can be written as a React hook
 * and has the following signature (dispatcher is the dispatcher returned by useReducer):
 *
 *     sideEffects(props : Props, state : State, dispatch : Dispatcher<Action>) : void
 *
 * The initial state is set by the initial argument.
 *
 * The resulting component will have the same props as Component, with the values of the state added it.
 * as well as the dispatcher:
 *
 *     ResultingProps = Props & State & { dispatch : Dispatcher<Action> }
 */
export function addState<P extends object, S extends object, A>(
    Component: FC<WithProps<P, S, A>>,
    reducer: Reducer<P, S, A>,
    initial: Initial<P, S>,
    sideEffects?: SideEffects<P, S, A>,
): FC<P> & { fragment?: DocumentNode } {
    function Wrapped(props: P): ReactElement {
        const init: S = typeof initial === 'object' ? initial : initial(props)

        function reduce(state: S, action: A): S {
            // Pass the props to the reducer
            return reducer(props, state, action)
        }

        const [state, dispatch] = useReducer(reduce, init)
        if (sideEffects) {
            sideEffects(props, state, dispatch)
        }

        return <Component {...props} {...state} dispatch={dispatch} />
    }

    Object.defineProperty(Wrapped, 'name', { value: `addState(${Component.name})`, writable: false })
    Wrapped.defaultProps = Component.defaultProps

    return Wrapped
}
