import { ReactElement, ReactNode } from 'react'
import classnames from 'classnames'

import css from './styles.css'

type Props = {
    children: ReactNode
    className?: string
}

export function Layout(props: Props): ReactElement {
    const { children, className } = props
    return <div className={classnames(css.root, className)}>{children}</div>
}
