import { ReactElement, ReactNode, forwardRef, Ref, ComponentType } from 'react'
import classnames from 'classnames'

import css from './styles.css'

type Props = {
    children: ReactNode
    className?: string
}

export function Content(props: Props): ReactElement {
    const { children, className } = props
    const classname = classnames(css.content, className)

    return (
        <div className={classname}>
            {children}
            <div className={css.clear} />
        </div>
    )
}

export function NoMaxWidthContent(props: Props): ReactElement {
    const { children, className } = props
    const classname = classnames(css.contentNoMaxWidth, className)

    return (
        <div className={classname}>
            {children}
            <div className={css.clear} />
        </div>
    )
}

type LocationProps = {
    order?: number
    hideMobile?: boolean
    children: ReactNode
}

export const Sidebar = forwardRef(function SidebarI(props: LocationProps, ref: Ref<HTMLDivElement>): ReactElement {
    const { order, hideMobile, children } = props
    return (
        <div className={classnames(css.side, (order === -1 || hideMobile) && css.nomobile)} style={{ order }} ref={ref}>
            {children}
        </div>
    )
})

export const Full = forwardRef(function FullI(props: LocationProps, ref: Ref<HTMLDivElement>): ReactElement {
    const { order, hideMobile, children } = props
    return (
        <div className={classnames(css.full, (order === -1 || hideMobile) && css.nomobile)} style={{ order }} ref={ref}>
            {children}
        </div>
    )
})

export const Main = forwardRef(function (props: LocationProps, ref: Ref<HTMLDivElement>): ReactElement {
    const { order, hideMobile, children } = props
    return (
        <div className={classnames(css.main, (order === -1 || hideMobile) && css.nomobile)} style={{ order }} ref={ref}>
            {children}
        </div>
    )
})
Main.displayName = 'Main'

type Order<T extends string> = {
    Main: ComponentType<OrderProps<T>>
    Sidebar: ComponentType<OrderProps<T>>
    Full: ComponentType<OrderProps<T>>
}

type OrderProps<T extends string> = {
    name?: T
    children: ReactNode
    ref?: Ref<HTMLDivElement>
}

export function createOrder<T extends string>(order: T[]): Order<T> {
    const byName: Record<string, number> = {}
    for (let i = 0; i < order.length; i++) {
        byName[order[i]] = i
    }

    const MainI = forwardRef(function (props: OrderProps<T>, ref: Ref<HTMLDivElement>): ReactElement {
        const { name, children } = props
        const order = name ? byName[name] : -1
        return (
            <Main order={order} ref={ref}>
                {children}
            </Main>
        )
    })
    MainI.displayName = 'Order.Main'

    const SidebarI = forwardRef(function (props: OrderProps<T>, ref: Ref<HTMLDivElement>): ReactElement {
        const { name, children } = props
        const order = name ? byName[name] : -1
        return (
            <Sidebar order={order} ref={ref}>
                {children}
            </Sidebar>
        )
    })
    SidebarI.displayName = 'Order.Sidebar'

    const FullI = forwardRef(function (props: OrderProps<T>, ref: Ref<HTMLDivElement>): ReactElement {
        const { name, children } = props
        const order = name ? byName[name] : -1
        return (
            <Full order={order} ref={ref}>
                {children}
            </Full>
        )
    })
    FullI.displayName = 'Order.Full'

    return {
        Main: MainI,
        Sidebar: SidebarI,
        Full: FullI,
    }
}
