import { ReactElement, ReactNode } from 'react'
import { I18n, setupI18n } from '@lingui/core'

import { catalogs } from '../../locales'
import { LocaleProvider, Language } from '../lib/i18n'
import { useSetViewerLanguageMutation } from '../lib/set-user-language'

export async function init(locale: Language): Promise<I18n> {
    const i18n = setupI18n()
    await activate(i18n, locale)
    return i18n
}

async function activate(i18n: I18n, locale: Language): Promise<void> {
    const catalog = await catalogs[locale]()
    i18n.loadLocaleData(locale, { plurals: catalog.plurals })
    i18n.load(locale, catalog.messages)
    i18n.activate(locale)
}

type Props = {
    i18n: I18n
    children?: ReactNode
}

export function WithLocale(props: Props): ReactElement {
    const { children, i18n } = props
    const setUserLanguage = useSetViewerLanguageMutation()

    return (
        <LocaleProvider i18n={i18n} activate={activate} onUserLanguageChange={setUserLanguage}>
            {children}
        </LocaleProvider>
    )
}
