import { ReactElement, createContext, useEffect, useState, useMemo, useRef, useContext as useReactContext } from 'react'

export type Category = 'nav' | 'action' | 'forum'
export type Shortcut = {
    shortcut: string[]
    title: ReactElement
    handler: () => void
    category: Category
}

type ShortcutContext = {
    shortcuts: Shortcut[]
    register(shortcut: Shortcut): void
    deregister(keys: string[]): void
}

export const context = createContext<ShortcutContext>({
    shortcuts: [],
    register: noop,
    deregister: noop,
})

export function useContext(): ShortcutContext {
    return useReactContext(context)
}

function noop(): void {
    // no op
}

export function useShortcut(
    keys: string[],
    category: Category,
    title: ReactElement,
    handler: () => void = noop,
    when: unknown[] = [],
): void {
    const ctx = useContext()

    useEffect(
        function () {
            const shortcut = Array.isArray(keys) ? keys : [keys]
            ctx.register({
                shortcut,
                title,
                handler,
                category,
            })

            return (): void => ctx.deregister(shortcut)
        },
        [keys.join('.'), category, ...when],
    )
}

type Props = {
    shortcuts?: Shortcut[]
    children: ReactElement
}

export function ShortcutProvider(props: Props): ReactElement {
    const [shortcuts, setShortcuts] = useState(props.shortcuts ?? [])
    useListenShortcuts(shortcuts)

    const value = useMemo(
        function () {
            function register(shortcut: Shortcut): void {
                setShortcuts(function (current: Shortcut[]): Shortcut[] {
                    return [
                        ...current.filter(
                            (s: Shortcut): boolean => s.shortcut.join('.') !== shortcut.shortcut.join('.'),
                        ),
                        shortcut,
                    ]
                })
            }

            function deregister(keys: string[]): void {
                setShortcuts(function (current: Shortcut[]): Shortcut[] {
                    return current.filter((s: Shortcut): boolean => s.shortcut.join('.') !== keys.join('.'))
                })
            }

            return { shortcuts, register, deregister }
        },
        [shortcuts],
    )

    return <context.Provider value={value}>{props.children}</context.Provider>
}

type LastKey = {
    when: number
    key: string
}

const activeElements = ['INPUT', 'TEXTAREA', 'SELECT', 'OPTION']

function useListenShortcuts(shortcuts: Shortcut[]): void {
    const timeout = 400
    const lastkey = useRef<LastKey | null>(null)

    useEffect(
        function () {
            function handler(evt: KeyboardEvent): void {
                if (evt.ctrlKey || evt.altKey || evt.metaKey) {
                    return
                }

                const activeElement = evt.composedPath()[0] as Element

                if (activeElements.includes(activeElement.tagName)) {
                    lastkey.current = null
                    return
                }

                const combo = [evt.key]

                if (lastkey.current && Date.now() - lastkey.current.when < timeout) {
                    combo.unshift(lastkey.current.key)
                }

                const shortcut =
                    shortcuts.find((defn: Shortcut): boolean => defn.shortcut.join('.') === combo.join('.')) ??
                    shortcuts.find((defn: Shortcut): boolean => defn.shortcut.join('.') === evt.key)

                if (!shortcut) {
                    lastkey.current = { key: evt.key, when: Date.now() }
                    return
                }

                evt.preventDefault()
                // lastkey.current = null
                shortcut.handler()
            }

            window.addEventListener('keydown', handler)
            return (): void => window.removeEventListener('keydown', handler)
        },
        [shortcuts],
    )
}
