import { ReactElement } from 'react'
import { Title } from 'react-head'
import { Trans } from '@lingui/macro'

import { Link } from '../link'
import { Content } from '../content'
import { Status } from '../status'

import css from './styles.css'

const messages = {
    400: <Trans>Bad request.</Trans>,
    401: <Trans>Unauthorized.</Trans>,
    402: <Trans>Payment required.</Trans>,
    403: <Trans>Forbidden.</Trans>,
    404: <Trans>We could not find that page.</Trans>,
    405: <Trans>Method not allowed.</Trans>,
    406: <Trans>Not acceptable.</Trans>,
    407: <Trans>Proxy authentication required.</Trans>,
    408: <Trans>Request timeout.</Trans>,
    409: <Trans>Conflict.</Trans>,
    410: <Trans>Gone.</Trans>,
    411: <Trans>Length required.</Trans>,
    412: <Trans>Precondition failed.</Trans>,
    413: <Trans>Request entity too large.</Trans>,
    414: <Trans>Request-URI too long.</Trans>,
    415: <Trans>Unsupported media type.</Trans>,
    416: <Trans>Requested range not satisfiable.</Trans>,
    417: <Trans>Expectation failed.</Trans>,
    418: <Trans>I&apos;m a teapot.</Trans>,
    419: <Trans>Insufficient space on resource.</Trans>,
    420: <Trans>Method failure.</Trans>,
    421: <Trans>Misdirected request.</Trans>,
    422: <Trans>Unprocessable entity.</Trans>,
    423: <Trans>Locked.</Trans>,
    424: <Trans>Failed dependency.</Trans>,
    428: <Trans>Precondition required.</Trans>,
    429: <Trans>Too many requests.</Trans>,
    431: <Trans>Request header fields too large.</Trans>,
    451: <Trans>Unavailable due to legal reasons.</Trans>,
    500: <Trans>Something went wrong.</Trans>,
    501: <Trans>Not implemented.</Trans>,
    502: <Trans>Bad gateway.</Trans>,
    503: <Trans>Service unavailable.</Trans>,
    504: <Trans>Gateway timeout.</Trans>,
    505: <Trans>HTTP version not supported.</Trans>,
    507: <Trans>Insufficient storage.</Trans>,
}

export type DiscogsError = {
    status?: number
    message?: ReactElement | string
}

export function ErrorMessage(props: DiscogsError): ReactElement {
    const { status = 500, message } = props

    // @ts-expect-error: TypeScript does not understand keys
    const msg = message ?? (status.toString() in messages ? messages[status] : messages['500'])

    return (
        <Content>
            <Title>Discogs</Title>
            <Status code={status} />
            <h1 className={css.title}>
                <Trans>Error</Trans> {status}.
            </h1>
            <p className={css.message}>{msg}</p>
            <p>
                <Link href='https://www.discogs.com'>discogs.com</Link> &mdash;{' '}
                <Link href='https://www.discogs.com/help'>
                    <Trans>Help!</Trans>
                </Link>{' '}
                &mdash; <Link href='https://www.twitter.com/discogs'>@discogs</Link>
            </p>
        </Content>
    )
}
