import { ReactElement } from 'react'
import { Link, Meta } from 'react-head'

import png from './favicons/favicon.png'
import mask from './favicons/mask.svg'
import appleTouch from './favicons/apple-touch-icon.png'
import ms from './favicons/mstile.png'

export function Favicon(): ReactElement {
    return (
        <>
            <Link rel='shortcut icon' type='image/png' href={png} />
            <Link rel='mask-icon' href={mask} color='#333' />
            <Link rel='apple-touch-icon' sizes='180x180' href={appleTouch} />
            <Meta name='msapplication-TileColor' content='white' />
            <Meta name='msapplication-TileImage' content={ms} />
        </>
    )
}
