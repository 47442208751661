import { ReactElement, ReactNode } from 'react'
import classnames from 'classnames'

import css from './styles.css'

export type IconProps = JSX.IntrinsicAttributes & {
    children?: ReactNode
    className?: string
    style?: {
        [k: string]: string | number
    }
    alt?: string
}

type SVGProps = IconProps & {
    viewBox: string
    children?: ReactNode
    role?: string
}

export function SVG(props: SVGProps): ReactElement {
    const { viewBox, children, className, role = 'img', ...rest } = props
    const classname = classnames(css.icon, className)

    return (
        <svg {...rest} viewBox={viewBox} className={classname} role={role}>
            {children}
        </svg>
    )
}
